<template>
	<div class="weatherBox">
		<div class="titleBox">
			<div class="dateBox">
				<div>
					<span class="year">{{ todayDate.year }}</span>
				</div>
				<div>
					<span class="date">{{ todayDate.date }}</span>
					<span class="week">{{ todayDate.dayOfTheWeek }}</span>
				</div>
			</div>
			<div class="clockBox">
				<div><img src="/images/icon-clock.png" /></div>
				<span>{{ todayDate.clock }}</span>
			</div>
		</div>
		<div class="contentBox">
			<div style="height: 81px">
				<span class="login-user-site">{{ loginUserSiteNm }}</span>
				<div class="fas weather fa-4x current-weather-icon" v-bind:class="today.icon"></div>
				<span class="current-temp">{{ today.temperature }}°</span>
				<div>
					<span class="current-weather-info">습도 : {{ today.humidity }}</span>
					<span class="current-weather-info">풍속 : {{ today.windSpeed }}m</span>
					<span class="current-weather-info">풍향 : {{ today.windDeg }}</span>
				</div>
			</div>
			<div style="height: 72px">
				<!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
				<div v-for="(row, index) in dateWeek" v-if="index < 3" :key="index" class="week-temp">
					<div style="padding-top: 10px">
						<div>{{ row.dateWeekDay }}</div>
						<div class="week-date">{{ row.month }}.{{ row.date }}</div>
					</div>
					<div class="fas weather week-weather-icon" v-bind:class="row.icon" style="padding-top: 17px"></div>
					<div style="padding-top: 7px">
						<span style="color: red">{{ row.temperatureMax }}°</span>
						<br />
						<span style="color: blue">{{ row.temperatureMin }}°</span>
					</div>
				</div>
			</div>
			<div style="height: 72px">
				<!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
				<div v-for="(row, index) in dateWeek" v-if="index > 2 && index < 6" :key="index" class="week-temp">
					<div style="padding-top: 10px">
						<div>{{ row.dateWeekDay }}</div>
						<div class="week-date">{{ row.month }}.{{ row.date }}</div>
					</div>
					<div class="fas weather week-weather-icon" v-bind:class="row.icon" style="padding-top: 17px"></div>
					<div style="padding-top: 7px">
						<span style="color: red">{{ row.temperatureMax }}°</span>
						<br />
						<span style="color: blue">{{ row.temperatureMin }}°</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../api/index';
const mainUrl = apiIndex.main;

export default {
	props: {
		loginUserSiteNm: { type: String, required: false, default: '현재날씨' },
	},
	mounted() {
		this.init();
	},
	data: () => ({
		weekDayStrArr: ['일', '월', '화', '수', '목', '금', '토'],
		dateWeek: [],
		today: { weatherIcon: 'fa-cloud-sun' },
		// 현재시각 표시
		todayDate: {
			year: '',
			date: '',
			dayOfTheWeek: '',
			clock: '',
		},
	}),
	methods: {
		addZero(string) {
			// 값이 1자리면 앞에 '0' 붙임
			return string[1] ? string : '0' + string[0];
		},
		clock() {
			// 현재 시각
			const date = new Date();
			const yyyy = date.getFullYear().toString();
			const mm = (date.getMonth() + 1).toString();
			const dd = date.getDate().toString();
			const h = date.getHours().toString();
			const m = date.getMinutes().toString();
			const s = date.getSeconds().toString();
			const week = ['일', '월', '화', '수', '목', '금', '토'];

			this.todayDate = {
				year: yyyy,
				date: this.addZero(mm) + '. ' + this.addZero(dd) + '. ',
				dayOfTheWeek: week[date.getDay()] + '요일',
				clock: this.addZero(h) + ':' + this.addZero(m) + ':' + this.addZero(s),
			};

			// 30초마다 reload?
			if (this.tick++ % 5 !== 0) return;

			this.tick = 1;
		},
		init() {
			this.clock(); // 딜레이 없이 바로 표시하기 위함
			setInterval(() => {
				this.clock();
			}, 1000); // 1초 마다 시간 변경

			if (navigator.geolocation) {
				this.handleGeoSucces();
			} else {
				this.handleGeoError();
			}
		},
		handleGeoSucces() {
			this.$axios.post(mainUrl.getWeatherInfo, {}).then(r => {
				this.getWeather(r.data.latitude, r.data.longitude);
			});
		},
		handleGeoError() {
			console.log("Can't access geo location");
		},
		getWeather(lat, log) {
			const API_KEY = 'f42c8c69cb4e3987a7a8c8cc2eb15cc4';
			this.$axios.get(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${log}&appid=${API_KEY}&units=metric`).then(response => {
				const weatherData = response.data;

				// 오늘 포함 8일을 읽기 때문에 7일까지만 사용
				for (let i = 1; i < weatherData.daily.length; i += 1) {
					const weatherDate = new Date(weatherData.daily[i].dt * 1000);
					const data = Object();
					data.dateWeekDay = this.weekDayStrArr[weatherDate.getDay()]; // 요일
					data.month = this.addZero(String(weatherDate.getMonth() + 1));
					data.date = this.addZero(String(weatherDate.getDate()));
					data.icon = this.getWeatherIcon(weatherData.daily[i].weather[0].icon); // 날씨 아이콘
					data.temperatureMax = Math.round(weatherData.daily[i].temp.max); // 최고 기온
					data.temperatureMin = Math.round(weatherData.daily[i].temp.min); // 최소 기온
					this.dateWeek.push(data);
				}

				this.today.temperature = Math.round(weatherData.current.temp); // 온도
				this.today.windSpeed = weatherData.current.wind_speed; // 풍속
				this.today.windDeg = this.getWindDeg(weatherData.current.wind_deg); // 풍향
				this.today.humidity = weatherData.current.humidity; // 습도
				this.today.icon = this.getWeatherIcon(weatherData.current.weather[0].icon); // 날씨 icon
			});
		},
		getWeatherIcon(iconState) {
			switch (iconState) {
				case '01d':
				case '01n':
					return 'fa-sun';
				case '02d':
				case '02n':
					return 'fa-cloud-sun';
				case '03d':
				case '03n':
					return 'fa-cloud';
				case '04d':
				case '04n':
					return 'fa-cloud';
				case '09d':
				case '09n':
					return 'fa-cloud-showers-heavy';
				case '10d':
				case '10n':
					return 'fa-umbrella';
				case '11d':
				case '11n':
					return 'fa-poo-storm';
				case '13d':
				case '13n':
					return 'fa-snowflake';
				case '50d':
				case '50n':
					return 'fa-smog';
				default:
					return 'fa-sun';
			}
		},
		getWindDeg(windDeg) {
			const result = Math.floor((windDeg + 22.5 * 0.5) / 22.5);
			switch (result) {
				case 0:
					return '북';
				case 1:
					return '북북동';
				case 2:
					return '북동';
				case 3:
					return '동북동';
				case 4:
					return '동';
				case 5:
					return '동남동';
				case 6:
					return '남동';
				case 7:
					return '남남동';
				case 8:
					return '남';
				case 9:
					return '남남서';
				case 10:
					return '남서';
				case 11:
					return '서남서';
				case 12:
					return '서';
				case 13:
					return '서북서';
				case 14:
					return '북서';
				case 15:
					return '북북서';
				case 16:
					return '북';
				default:
					return 'undefined';
			}
		},
	},
};
</script>

<style>
.weatherBox {
	width: 100%;

	box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.weatherBox > .titleBox {
	display: flex;
	height: 70px;
	padding: 10px 0 0 10px;
	background: #fafafa;
	font-weight: bold;
}
.titleBox > .dateBox {
	width: 45%;
	padding-left: 10px;
}
.dateBox > div {
	margin-bottom: -15px;
}
.dateBox > div > .year {
	font-size: 1em;
	margin-right: 10px;
}
.dateBox > div > .date {
	font-size: 1.5em;
	margin-right: 5px;
}
.titleBox > .clockBox {
	width: 50%;
}
.clockBox > div {
	display: inline-block;
}
.clockBox > div > img {
	width: 50px;
}
.clockBox > span {
	float: right;
	font-size: 2em;
	margin-top: -5px;
}
.weatherBox > .contentBox {
	padding: 5px 15px;
	font-weight: bold;
}
.weatherBox > .contentBox > div {
	width: 100%;
	display: flex;
	padding-bottom: 5px;
	border-bottom: 1px solid #ddd;
}
/* .weatherBox > .contentBox > div:not(:last-child) {
	border-bottom: 1px solid #ddd;
} */
.login-user-site {
	width: 120px;
	margin-right: 10px;
}
.current-weather-icon {
	margin: 5px -5px 0;
	font-size: 3.5em !important;
	margin-right: 5px;
}
.current-temp {
	width: 130px;
	font-size: 2.8em;
	text-align: center;
	margin-top: -5px;
	margin-right: 5px;
}
.current-weather-info {
	display: block;
	width: 80px;
	font-size: 9pt;
	line-height: 2em;
	overflow: hidden;
	white-space: nowrap;
}
.week-temp {
	display: flex;
	width: 33.3%;
	height: 100%;
	text-align: center;
	white-space: nowrap;
}
.week-temp > div {
	width: 33.3%;
}
.week-temp:not(:last-child) {
	border-right: 1px solid #ddd;
}
.week-date {
	font-weight: lighter;
	font-size: 0.8em;
}
.week-weather-icon {
	font-size: 2em;
}
</style>
